.Main-Crad h3 {
  display: flex;
  flex-direction: column;
  line-height: 2.4;
  letter-spacing: 1.3px;
}

.No-data-jobs {
  width: 100%;
  display: flex;
  height: 200px;
  color: #9d9fa0;
  align-items: center;
  justify-content: center;
}
.IconCircle {
  width: 100px;
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: center;
  /* font-size: 40px; */
  border-radius: 101%;
}
.first {
  background: #bfebca !important;
}
.second {
  background: #fee7b7 !important;
}
.third {
  background: #bae7f6 !important;
}

.Table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
}

.Table-header h5 {
  font-size: 16px;
  line-height: 0;
  padding: 0 !important;
  margin: 0 !important;
  color: #868e96;
  font-weight: 500;
}

.Table-header h5 div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.table-dashboard .header-row-table .TableHeader {
  font-weight: 700px !important;
}

.dashbord-table-icon {
  font-size: 16px !important;
  margin-right: 5px !important ;
}

.jobPostIcons {
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 20px;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  background-color: #f1f3f5;
  color: #868e96;
  border-radius: 50%;
}

.jobPostIcons .workIconClass {
  font-size: 18px;
}

.profileImageSectio {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white !important;
  padding: 40px !important;
}

.UserImage-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* border: 1px solid red; */
  border: linear-gradient(to right, rgb(180, 17, 17), rgb(5, 5, 210)) 0 0 50% 0 /
    1px;
  position: relative;
}

.UserImage-profile .user-status {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  display: flex;
  justify-content: center;
  height: 25px;
  background-color: #2b5238;
  border-radius: 50%;
  color: white !important;
}

.UserImage-profile img {
  width: 100%;
  padding: 10px;
  height: inherit;
  border-radius: 50%;
}

.profile-user-name {
  font-size: 18px;
  line-height: 0;

  color: #2b5238;
}

.main-role-user {
  color: gray;
  font-size: 14px;
  line-height: 0;
  text-align: center;
}

.experties-user {
  text-align: left;
  width: 100%;
}

.experties-user h6 {
  font-size: 14px;
  margin-bottom: 6px;
}

.experties-user ul {
  list-style-type: none;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 2px;
}

.experties-user ul li {
  border: 2px solid #f1f3f5;
  border-radius: 8px;
  margin: 5px;
  width: auto;
  font-size: 14px;
  padding: 3px 10px;
  display: flex;
  background: #f8f9fa;
}

.basic-info ul {
  list-style: none;
  margin-top: 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.basic-info ul li {
  display: flex;
  width: 33.3%;
  height: 110px;
  overflow: hidden;
  color: #868e96;
  display: flex;
  flex-direction: column;
  padding: 6px;
}

.basic-info ul li span {
  font-size: 17px;
  color: #343a40;
}

.job-description ul {
  list-style: none;
  margin-top: 20px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.job-description ul li {
  width: 33.3%;
  color: #868e96;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-right: 1px solid #cfd2d4;
}

.job-description ul li:last-child {
  border-right: none;
}

.job-detial-icon {
  font-size: 14px !important;
}

.user-chat {
  display: flex;
  flex-direction: column;
}
.userImages {
  display: flex;
  /* flex-direction: column; */
}

.allChatUserMesage .userImages:first-child {
  margin-left: 0;
}

/* .userImages > img:first-of-type {
  margin-left: 0;
} */
.userImages {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: -20px;
  padding: 10px 0px;
}

.userImages img {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  border: 1px solid green;
}

.userImages img:hover {
  transform: scale(1.1);
}

/* reset page */

.reset_section_page {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #e9ecef;
}

.list-section {
  width: 35%;
  height: 70vh;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.user-creation-content {
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-creation-content .logo-section {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f6274;
  font-size: 45px;
  transform: rotate(325deg);
}

/* .logo-section::before
 {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  background-color: red;
  z-index: -100;
}

.logo-section::before {
  /* width: 5%;
  padding-bottom: 5%;
  background-color: #3a7c92;
  z-index: -1000; */
/* }

.logo-section::after { */
/* width: 3%;
  padding-bottom: 3%;
  background-color: #6b9aa5;
  z-index: -100; */
/* } */

.sub_title-forgot {
  color: #9d9fa0;
  font-size: 18px;
}

/*============= serchbar ======================== */

.searchbar-mui-data-table {
  width: 300px;
  position: absolute;
  right: 120px;
  top: 8px;
  padding: 5px;
  z-index: 100;
  background: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.searchbar-mui-data-table input {
  border: none;
  padding: 5px;
  width: 90%;
  outline: none;
}

/* userandFixer Chat */


.user-messages ul{
  margin: 0;
  padding: 0;
}
.user-messages ul li {
  list-style: none;
  margin-bottom: 10px;
}

.message-section-text p {
  margin: 0;
  padding: 0;
}
/* .message-section-text {
  margin-left: 10px;
} */
.chat-profile {
  width: 40px;
  height: auto;
  display: flex;
  align-items: center;
}
.chat-profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}


.date-mode-selection{
  overflow: hidden;
  border-radius: 10px;
  color: white;
}

.date-mode-selection div{
  border-right: 1px solid white;
  padding: 3px 10px;
  background-color: #809788;
  cursor: pointer;
}
.date-mode-selection div:hover{
  transform: scale(1.1);
}

.date-mode-selection:nth-last-child(){
  border:none !important;
}

.date-mode-selection .isSelectedMode{
  background-color: #557560;
  
}

.rotate {
  animation: rotate 1s ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.users-list-need-help{
  border-bottom: 1px solid rgba(173, 171, 171, 0.224) !important;
  margin: 0 4px 0 4px;
}