body {
  margin: 0;
}

.profile-section {
  margin-left: 0px !important;
}

.wrapper {
  background: white;
  width: 100%;
  border-radius: 82px 0 0 0;
  overflow: hidden;
}

/* user section table */

/* background  tabble hreader color= #d7d5d5 */

.table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #d7d5d5;
}

.table-header h5 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 16px;
}

.tableHeading-Content {
  padding: 0px !important;
}

.MuiDataGrid-cell[data-field="rating"] {
  text-align: center;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  font-size: 13px !important;
}

.MuiDataGrid-colHeaderWrapper {
  border-left: none;
  border-right: none;
}

/* login page */
.logo-Image img {
  width: 50px;
  height: 50px;
}

.TabsWrapper .Tabs .loginTab {
  max-width: 50% !important;
  min-width: 50% !important;
  font-size: 12px !important;
}

.TabsWrapper .Tabs .SignupTab {
  max-width: 50% !important;
  min-width: 50% !important;
  font-size: 12px !important;
}

.checkbox-Forgot {
  margin-bottom: 5px !important;
}

.SideImage img {
  width: auto;
  height: 280px;
}

.SideImage {
  /* background: hsla(26, 76%, 63%, 1); */
  height: 280px;
  width: auto;
  /* border-radius: 81% 19% 52% 48% / 48% 33% 67% 52%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-Page {
  width: 90%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
}

.forgot-Page .forgot-content {
  padding: 20px;
}

.forgot-Page p {
  font-size: 10px;
  text-transform: uppercase;
  color: gray;
  line-height: 1.4;
}

.forgot-Page .Image-Section {
  margin-top: 30px;
  display: flex;
  width: inherit;
  display: flex;
  justify-content: center;
}

.backButton {
  position: absolute;
  left: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 100%;
  background-color: rgb(185, 180, 180);
}

.backButton .backarrow {
  width: 20px !important;
  height: 20px !important;
}

.backButton:hover {
  background-color: gray;
}

.imageContent {
  background: white;
  border-radius: 4px;
  margin: 0 35px 0 20px;
  padding: 10px;
  /* margin-right: 20px; */
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

/* Sidebar */

.upDownArrow {
  position: relative;
}

.upDownArrow .upArrow {
  font-size: 14px;
  position: absolute;
  left: -20px;
  top: -5px;
}

.upDownArrow .downArrow {
  font-size: 14px;
  position: absolute;
  left: -20px;
  top: -5px;
}

/* Dashboard */
.Main-Crad {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
}

.Main-Crad h3 {
  font-size: 16px;
  line-height: 2;
  font-weight: 600;
  color: #868e96;
  letter-spacing: 0.6px;
}

.Main-Crad h3 span {
  font-size: 24px !important;
  font-weight: 700;
  color: #343a40;
}

/* message === Chat user page */
.chat-bot-section {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0 10px 15px !important;
  background: #40634c !important;
  color: white !important;
  border-radius: 5px 5px 0 0 !important;
}

.chat-bot-section img {
  margin-right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-bot-user-info {
  padding: 0 0 0 3px;
}

.chat-bot-user-email {
  color: #999;
  font-size: 12px;
}

/* message == list of users */

.list-user-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 8px;
}

.list-user-list-item .icon-and-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-user-image {
  margin-right: 8px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.heading-message-page {
  font-size: 16px;
  line-height: 0;
  letter-spacing: 0.6px;
  margin-left: 15px;
}


/* login eye icon */

.css-i4bv87-MuiSvgIcon-root{
  width: 20px !important;
  height: 20px !important;
}
